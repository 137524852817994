<template>
	<page-container :page="page" class="index">
		<heading-image type="large">
			<div v-if="page.vimeoID.length" ref="headingImageElem">
				<div style="padding: 56.25% 0 0; position: relative">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
					<iframe
						:src="
							'https://player.vimeo.com/video/' +
							page.vimeoID +
							'?h=48e73401ce&amp;loop=1&amp;muted=1&amp;badge=0&amp;autoplay=1&amp;controls=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
						"
						class="subpage-iframe"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
						title="Cafe Panache"
					/>
				</div>
			</div>
			<div v-else>
				<hero-image v-if="page.headingImage" class="header-image">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
					<picture>
						<source
							v-if="page.headingImagePortraitWebp"
							:srcset="page.headingImagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="page.headingImagePortrait"
							:srcset="page.headingImagePortrait"
							media="(max-width: 500px)"
						/>
						<source :srcset="page.headingImageWebp" type="image/webp" />
						<source :srcset="page.headingImage" />
						<img :src="page.headingImage" :alt="page.headingImageAlt" />
					</picture>
				</hero-image>
			</div>
			<template #scroll>
				<a class="scroll-down" @click="scrollDown()">
					<img src="~/assets/images/arrow-down-white.png" alt="Scroll" />
				</a>
			</template>
		</heading-image>

		<main-content>
			<div class="main-intro">
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="intro-text">
					<div class="flex-wrapper">
						<h2 class="subtitle">
							{{ page.subtitle }}
						</h2>
						<h1>{{ page.title }}</h1>
						<div v-parse-links v-html="page.content" />
					</div>
				</div>
			</div>
		</main-content>

		<promoblocks :items="promoblocksData" />

		<section class="section-reservations">
			<div class="row">
				<div class="columns column8">
					<h2 v-if="defaults[locale].homepage.sectionReservationsSubtitle" class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
				<div class="columns column4 align-right">
					<a
						v-if="defaults[locale].homepage.sectionReservationsLink"
						:href="defaults[locale].homepage.sectionReservationsLink"
						:target="defaults[locale].homepage.sectionReservationsTarget"
						class="button"
					>
						{{ defaults[locale].homepage.sectionReservationsButton }}
					</a>
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 v-if="defaults[locale].homepage.promotionsSubtitle" class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img class="promotions-image" :src="defaults[locale].homepage.promotionBackgroundImage" />
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row flex-row">
				<div class="columns column4">
					<div class="flex-wrapper">
						<h3>{{ defaults[locale].website.restaurantName }}</h3>
						<p>
							{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
							{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								{{ defaults[locale].website.phone }} </a
							><br />
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
								{{ defaults[locale].website.email }}
							</a>
						</p>
					</div>
				</div>
				<div class="columns column8">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.2617785095!2d4.8653067769847995!3d52.36567314764371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609e029ad9c41%3A0x22fd71251c6dc543!2sCaf%C3%A9%20Panache!5e0!3m2!1snl!2snl!4v1682677898213!5m2!1snl!2snl"
						width="100%"
						height="500"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<section class="snapwidget">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						<a href="https://www.instagram.com/cafe_panache/" target="_blank"> @cafe_panache </a>
					</h2>
					<h2>Come hang out with us</h2>
				</div>
				<div class="columns column12">
					<iframe
						src="https://snapwidget.com/embed/1034297%22"
						class="snapwidget-widget"
						allowtransparency="true"
						frameborder="0"
						scrolling="no"
						style="border: none; overflow: hidden; width: 100%"
					/>
				</div>
			</div>
		</section>

		<section class="also-visit">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 v-if="defaults[locale].homepage.alsoVisitSubtitle" class="subtitle">
						{{ defaults[locale].homepage.alsoVisitSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.alsoVisitHeader }}</h2>
				</div>
				<div class="columns column12 align-center logo-row">
					<a href="https://finddoor74.com/" target="_blank">
						<img src="~/assets/images/logo-door74.png" loading="lazy" alt="Door 74" />
					</a>
					<a href="https://cafeverlan.com/" target="_blank">
						<img src="~/assets/images/logo-verlan.png" loading="lazy" alt="Verlan" />
					</a>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const headingImageElem = ref(null);

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const scrollDown = () => {
	const scrollHeight = headingImageElem.value.clientHeight;
	window.scroll({
		top: scrollHeight - 150,
		left: 0,
		behavior: 'smooth',
	});
};

useHead({
	script: [
		{
			src: 'https://snapwidget.com/js/snapwidget.js',
			type: 'text/javascript',
			body: true,
		},
	],
});
</script>

<style lang="scss" scoped>
.snapwidget {
	background: $primary-background-color;
	padding: 75px 0;

	h2 {
		margin: 0 auto 40px;

		a {
			color: #fff;
		}
	}
}

.header-image {
	height: 100vh !important;
}

.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: #fff;
	max-width: 820px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 400;
		color: #fff;
		font-size: 80px;
		margin-bottom: 10px;
		font-family: $heading-font-family;
		letter-spacing: 2px;
		line-height: 1;
	}

	:deep(> *) {
		margin: 0 0 10px;
		font-size: 24px;
	}
}

.scroll-down {
	position: absolute;
	bottom: 50px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	z-index: 4;
	cursor: pointer;

	&:hover img {
		transform: scale(1.1);
	}

	img {
		width: 47px;
		height: 47px;
		transition: all 0.3s ease-in-out;
	}
}

.also-visit {
	background: $secondary-background-color;
	padding: 75px 0;

	h2:not(.subtitle) {
		color: #fff;
	}

	.logo-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;

		a {
			margin: 20px;
		}

		img {
			max-width: 159px;
		}
	}
}

.main-intro {
	width: 100%;
	margin: auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: stretch;
	gap: 30px;

	.intro-text,
	.intro-image {
		width: 50%;
	}

	.intro-text {
		padding: 80px 60px 70px;
		background: #f3f1ec;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-flow: row wrap;
	}

	.intro-image {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.section-reservations {
	padding: 100px 0;
	background-color: $primary-background-color;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	.form-wrapper {
		padding: 40px 30px;
		background: $secondary-background-color;

		.button {
			width: auto;
			margin: 10px 0 0;
			padding: 15px 30px;
		}

		input,
		select,
		textarea {
			color: #fff;
			width: 100%;
			max-width: 500px;
			padding: 20px;
			border: none;
			box-shadow: 0 0 5px rgba(0 0 0 / 10%);
			font-size: 13px;
			font-family: $body-font-family;
		}
	}

	.reservation-form {
		display: flex;
		flex-flow: row wrap;
		gap: 25px;

		> div {
			width: calc(50% - 13px);
		}
	}
}

.section-promotion {
	background: $secondary-background-color;
	margin: 0;
	padding: 200px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 35%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;

		.subtitle {
			color: #fff;
			border-color: #fff;
		}
	}
}

.section-contact {
	background: $secondary-background-color;

	.row:first-child {
		padding-bottom: 50px;
		color: #fff;

		h2,
		h3 {
			color: #fff;

			&.subtitle {
				color: $subtitle-color;
			}
		}
	}

	.flex-row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		gap: 20px;

		.column4 {
			background: #f3f1ec;
			padding: 40px 60px;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-flow: row wrap;

			.flex-wrapper {
				width: 100%;
			}
		}
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		grid-template-columns: 100%;
		flex-flow: column wrap;

		.intro-text {
			padding: 60px 40px;
		}

		.intro-text,
		.intro-image {
			width: 100%;
		}
	}
}

@media (max-width: 900px) {
	.section-reservations {
		.row {
			.column4 {
				width: 100%;
				text-align: center;
			}
		}
	}
}

@media (max-width: 880px) {
	header.large {
		min-height: 640px;

		iframe {
			width: 360% !important;
			height: 130% !important;
			margin-left: -130% !important;
			min-height: 640px !important;
		}

		> div,
		> div > div {
			min-height: 640px;
			width: 100%;
		}
	}

	header.small {
		height: 60vh;
	}

	.section-promotions {
		padding: 125px 25px;
	}

	.section-contact {
		.flex-row {
			flex-flow: column wrap;

			.column4 {
				padding: 40px;
			}

			.column8 {
				padding: 0;
			}
		}

		.columns {
			width: 100%;
		}
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}
	}

	.section-reservations {
		text-align: center;

		.reservation-form > div {
			width: 100%;
		}
	}
}

@media (max-width: 640px) {
	header.large {
		min-height: 520px;

		iframe {
			min-height: 520px !important;
		}

		> div,
		> div > div {
			min-height: 520px;
		}
	}

	.hero-content {
		font-size: 14px;
		padding: 0 3px;

		.hero-title {
			font-size: 24px;
		}

		:deep(p) {
			font-size: 17px;
			line-height: 24px;
		}
	}
}
</style>
